import { Entry } from "contentful";

import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import MediaAsset from "./media-asset";

type Props = {
  entry: Entry<unknown>;
  className?: string;
  fill?: boolean;
  width?: number;
  height?: number;
  videoConfig?: {
    muted?: boolean;
    loop?: boolean;
    autoPlay?: boolean;
    controls?: boolean;
    playsInline?: boolean;
  };
  noLazy?: boolean;
};

export default function BreakpointAwareAsset(props: Props) {
  const breakpoint = useBreakpoint();

  if (!props.entry || !isActiveEntry(props.entry) || !breakpoint) return null;

  assertEditorialType<Editorials.ContentAwareAsset>(props.entry, "breakpointAwareAsset");

  const asset = breakpoint === "mobile" ? props.entry.fields.mobileAsset : props.entry.fields.desktopAsset;

  return <MediaAsset {...props} entry={asset} />;
}
